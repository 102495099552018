<template>
    <div>
        <div class="fullscreenLoading" v-show="fullscreenLoading">
            <div :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }" class="wrap_full">
            <img  :src="require('@/assets/card/loading.gif')"  class="img_card" />
        </div>
    </div>
    <div class="bg_wrap" v-if="state != 'going' && state == 'unstart' || state != 'going' && state == 'finish'">
            <div v-if="state != 'going' && state == 'unstart'" class="activity-start"    
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }" :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }">
                <img  :src="require('@/assets/card/start_bg.png')" class="img_card" />
            </div>
           
                <div v-if="state != 'going' && state == 'finish'" class="activity-finish" 
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }" :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }">
                <img  :src="require('@/assets/card/end_bg.png')" class="img_card" />
            </div>
        </div>
       <block v-show="acceptData.screen_type!=0">
         <div class="activity" v-show="!fullscreenLoading" >
            <img :src="acceptData?.adv_bottom_img"
                style="position: absolute;top:0;bottom:0;left:0;right:0;width: 100%;height: 100%;" v-if="acceptData.screen_type != 0" />
                <div v-else style="height: 100vh; position: relative;background-repeat: no-repeat!important;background-position: top!important;" :style="{ 'background-color':  mode == 'v' ? acceptData?.activity?.bottom_color:'', }">
                </div>
                <div class="activity-wrap" :style="{ 'width': wrapWidth, 'height': wrapHeight }"
            :class = "{'mCenter':mode == 'h'&&screen_part_type == 2 ,'dingwei':mode == 'v','resopnse':acceptData.screen_type==0 }" ref="activityWrap"
            >
           <div :class="{'liangfen': screen_part_type == 3 && fenshu == 'v'}" :style="{ 'top':acceptData.screen_type!=0 &&screen_part_type == 3 && fenshu == 'v'?mt:''}">

            <div  v-show="state == 'going'" ref="container"  style="background-size: cover;"
                    :class="{ 'activityResponse':this.acceptData.screen_type != 0,'activity-container':acceptData.screen_type == 0,'horizontal': mode == 'h', 'vertical': mode == 'v', 'no_adv': !acceptData.activity.left_banner_img }" :style="{ 'background-color':  acceptData.screen_type!=0 ?acceptData?.activity?.bottom_color:''}">
                <div class="activity-counter" ref="counter" v-if="JSON.stringify(countObj) != '[]'  && (acceptData.activity && (acceptData.activity.online_show == 1 || acceptData.activity.join_show == 1) || acceptData.reside_show == 1 || acceptData.flow_show == 1)">
                    <ul>
                        <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.online_show == 1" :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }">
                        <strong class="count-font green">{{countObj.online || 0}}</strong>
                        <span>实时在线人数</span>
                        </li>
                        <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.join_show == 1" :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }">
                        <strong class="count-font orange">{{countObj.join || 0}}</strong>
                        <span>累计参与人数</span>
                        </li>
                        <li v-if="acceptData.reside_show == 1" :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }">
                        <strong class="count-font yellow">{{countObj.flow}}</strong>
                        <span>驻留人数</span>
                        </li>
                        <li v-if="acceptData.flow_show == 1" :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }">
                        <strong class="count-font purple">{{countObj.pass}}</strong>
                        <span>客流人次</span>
                        </li>
                    </ul>
                </div>
                 <div class="activity-main" ref="main">
                    <div class="activity-content-left"   v-if="mode == 'h'" :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-left': mleft - 30 + 'px',backgroundColor: acceptData?.activity?.bottom_color }">
                        <div class="activity-module-qrcode" v-if="acceptData && acceptData.activity.qrcode_img" :style="{'background-image': 'url('+ acceptData.activity.qrcode_img + ')'}">
                            <div class="activity-module-qrcode-item">
                                <img :src="acceptData.activity.qrcode_path"/>
                            </div>
                        </div>
                        <div class="activity-module-move">
                            <img :src="require('@/assets/questions/q_hand_h.gif')"/>
                        </div>
                        <div class="activity-module-prize" v-if="acceptData" :style="{'background-image': 'url('+ acceptData.activity.prize_img + ')'}">
                            <div class="activity-module-prize-carousel">
                                <el-carousel indicator-position="none" height="210px" :interval="5000" @change="carouselChange" v-if="prizeList.length > 0">
                                    <el-carousel-item v-for="(item,index) in prizeList" :key="index">
                                        <img v-if="item.victory_img" class="activity-module-prize-carousel-img" :src="item.victory_img" />
                                        <img v-else class="activity-module-prize-carousel-img" src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b"/>
                                        <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                                    </el-carousel-item>
                                </el-carousel>                                
                            </div>
                            <div class="prize-num">
                                <span>剩余</span><br/>
                                <span class="count">{{prizeList.length ? prizeList[currentIndex].victory_unused_num : 0}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="activity-content-middle"  :style="{backgroundImage: 'url(' + (mode == 'h' ? acceptData?.activity.bg_img : acceptData?.activity.bg_h_img) + ')','margin-right': mleft + 'px', 'margin-left': mleft -30 + 'px'}">
                        <div class="activity_ques-wrap">
                            <div class="activity_ques" id="questions" :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')')}">
                                <div class="activity_ques-item" v-if="answerObj.title">
                                    <img class="activity_ques-img" v-if="answerObj.img" :src="answerObj.img" />
                                    <p class="activity_ques-title" :style="!answerObj.img ? 'flex: auto;text-align: center' : ''">
                                        {{answerObj.title}}
                                    </p>
                                </div>
                
                            </div>
                        </div>
                        <div class="activity-title"  :style="{'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'background-image': 'url('+ acceptData.activity.title_img + ')'}"   :class="{ 'tebie':screen_part_type!=0 }">
                            <div class="activity-time-down" >
                                <svg class="activity_time_ring" width="180" height="180">
                                    <circle class="activity_time_ringb" cx="90" cy="90" r="76"></circle>
                                    <circle class="activity_time_ringbg" cx="90" cy="90" r="76"></circle>
                                    <circle class="activity_time_ringitem" id="ring" ref="ring" cx="90" cy="90" r="76"></circle>
                                </svg>
                                <span class="activity-time-down-num"><strong ref="ringTxt" v-if="acceptData.screen_type!=0">0</strong></span>
                            </div>
                            <div class="activity-status">{{statusText[status]}}</div>
                        </div> 
                        <div class="activity-content">
                            <div  :class="{ 'juzhong': acceptData.screen_type!=0}" class="activity-content-main"  :style="{ 'transform': acceptData.screen_type!=0? 'translateX(' + (100 - (100 / screenRadio)) / 2 + '%)' : '', 'left': acceptData.screen_type!=0? (100 - (100 / screenRadio)) / 2 + '%' : '', width: acceptData.screen_type!=0 ? (100 / screenRadio) + '%' : '', 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                <div class="activity-answer">
                                    <div class="activity-user-list" >
                                        <ul id="userA" ref="userA" v-if="acceptData.screen_type!=0"></ul>
                                    </div>
                                    <div class="activity-answer-text" id="answerA" ref="answerA" >
                                        <div class="activity-answer-text-inner"  v-if="answerObj.option_a">
                                            <span>A</span>
                                            {{answerObj.option_a}}
                                        </div>
                                    </div>
                                </div>
                                <div class="activity-answer" >
                                    <div class="activity-user-list">
                                        <ul id="userB" ref="userB" v-if="acceptData.screen_type!=0"></ul>
                                    </div>
                                    <div class="activity-answer-text" id="answerB" ref="answerB">
                                        <div class="activity-answer-text-inner"  v-if="answerObj.option_b">
                                            <span>B</span>
                                            {{answerObj.option_b}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>
                    <div class="activity-content-side"  :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': mleft + 'px' }" v-if="mode == 'h' && (acceptData.activity.left_banner_img || acceptData.activity.right_banner_img)">
                        <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                            <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img" />
                        </div>
                        <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                            <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img"/>
                        </div>
                    </div>
                 </div>
                 <div class="activity-content-bottom" v-if="mode == 'v'">
                    <div class="activity-module-qrcode"  :style="{'background-image': 'url('+ acceptData.activity.qrcode_img + ')'}">
                        <div class="activity-module-qrcode-item">
                            <img :src="acceptData.activity.qrcode_path"/>
                        </div>
                    </div>
                    <div class="activity-module-move">
                        <img :src="require('@/assets/questions/q_hand_v.gif')"/>
                    </div>
                    <div class="activity-module-prize" v-if="acceptData" :style="{'background-image': 'url('+ acceptData.activity.prize_img  + ')'}">
                        <div class="activity-module-prize-carousel">
                            <el-carousel indicator-position="none" height="210px" :interval="5000" @change="carouselChange" v-if="prizeList.length > 0">
                                <el-carousel-item v-for="(item,index) in prizeList" :key="index">
                                    <img v-if="item.victory_img" class="activity-module-prize-carousel-img" :src="item.victory_img" />
                                    <img v-else class="activity-module-prize-carousel-img" src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b"/>
                                    <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="prize-num">
                            <span>剩余</span><br/>
                            <span class="count">{{prizeList.length ? prizeList[currentIndex].victory_unused_num : 0}}</span>
                        </div>
                    </div>
                    
                 </div>
                 <div class="activity-adv" v-if="mode == 'v' && (acceptData.activity.left_banner_img || acceptData.activity.right_banner_img)">
                    <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                        <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img"/>
                    </div>
                    <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                        <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img"/>
                    </div>
                 </div>


               <div class="mask_yindao heng hengs" v-if="mode == 'h' & acceptData.screen_type != 0" ref="mask_yindao" :style="{ 'opacity': 0 }"  >
                        <div :class="['pop-' + 'show']">
                            <div class="mask_yindao_wrap">
                                <div class="mask_yindao_content" :class="{'mt240':screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length>=3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >=3}">
                                    <img class="sao" src="../../../assets/card/sao.png"
                                        :style="{ 'transform':mode == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadio + ')') }" />
                                    <div class="sao_wrap sao_wrap_img" :style="{ 'transform':mode == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadio + ')') }">
                                        <img class="code" :src="acceptData.activity.qrcode_path" />
                                    </div>
                                    <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                        :style="{ 'transform':mode == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadio + ')') }" />
                                </div>
                                <div class="layerLst_wrap" 
                                :class="{ 'weizhi': screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length>=3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >=3}"
                                :style="{ 'transform': prizeCountListT.length>=3? 'translateX(' + (100 - (100 / screenRadio)) / 2 + '%)' : '', 'left': prizeCountListT.length>=3? (100 - (100 / screenRadio)) / 2 + '%' : '', width: prizeCountListT.length>=3 ? (100 / screenRadio) + '%' : '', 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                    <div v-for="(item, index) in prizeCountListT" :key="index">
                                        <div class="victory_bg">
                                            <img class="victory_img" :src="item.victory_img" />
                                            <div class="victory_name">
                                                <p class="victory_text">{{ item.victory_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="mask_yindao ver" v-if="mode == 'v' && prizeCountListT.length == 5 &&acceptData.screen_type != 0" ref="mask_yindao"
                        :style="{ 'opacity': 0 }" >
                        <div class="mask_yindao_wrap mask_yindao_wrap05">
                            <div class="mask_yindao_content">
                                <img class="sao" src="../../../assets/card/sao.png"
                                    />
                                <div class="sao_wrap">
                                    <img class="code" :src="acceptData.activity.qrcode_path"
                                         />
                                </div>
                                <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                    />
                            </div>
                            <div class="layerLst_wrap layerLst_wrap05">
                                <div v-for="(item, index) in prizeCountListT" :key="index">
                                    <div class="victory_bg">
                                        <img class="victory_img" :src="item.victory_img" />
                                        <div class="victory_name">
                                            <p class="victory_text">{{ item.victory_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mask_yindao ver" v-else-if="mode == 'v' && prizeCountListT.length == 4 &&acceptData.screen_type != 0"
                        ref="mask_yindao" :style="{ 'opacity': 0 }" >
                        <div class="mask_yindao_wrap mask_yindao_wrap04">
                            <div class="mask_yindao_content">
                                <img class="sao" src="../../../assets/card/sao.png"
                                    />
                                <div class="sao_wrap">
                                    <img class="code" :src="acceptData.activity.qrcode_path"
                                        />
                                </div>
                                <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                    />
                            </div>
                            <div class="layerLst_wrap">
                                <div v-for="(item, index) in prizeCountListT" :key="index">
                                    <div class="victory_bg">
                                        <img class="victory_img" :src="item.victory_img" />
                                        <div class="victory_name">
                                            <p class="victory_text">{{ item.victory_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length < 4 &&acceptData.screen_type != 0"
                        ref="mask_yindao" :style="{ 'opacity': 0 }"  >
                        <div class="mask_yindao_wrap mask_yindao_wrap03">
                            <div class="mask_yindao_content">
                                <img class="sao" src="../../../assets/card/sao.png"
                                     />
                                <div class="sao_wrap">
                                    <img class="code" :src="acceptData.activity.qrcode_path"
                                        />
                                </div>
                                <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                    />
                            </div>
                            <div class="layerLst_wrap">
                                <div v-for="(item, index) in prizeCountListT" :key="index">
                                    <div class="victory_bg">
                                        <img class="victory_img" :src="item.victory_img" />
                                        <div class="victory_name">
                                            <p class="victory_text">{{ item.victory_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
            </div>
           </div>
        </div>
        </div> 
       </block>
       <block v-show="acceptData.screen_type==0">
        <div class="activity" v-show="!fullscreenLoading" :style="{backgroundColor: acceptData?.activity?.bottom_color }">
            <div v-if="state != 'going'" class="activity-start">
                <div class="activity-begin" ref="activityBtn">
                    <a class="activity-btn" :class="{'finish': state == 'finish'}" href="javascript:;"></a>
                </div>  
            </div>
            <div  ref="container" class="activity-container" v-show="state == 'going'" :class="{'horizontal': mode == 'h', 'vertical': mode == 'v', 'adv-mode': acceptData.activity.left_banner_img || acceptData.activity.right_banner_img}">
                <div class="activity-counter" ref="counter" v-if="JSON.stringify(countObj) != '[]'  && (acceptData.activity && (acceptData.activity.online_show == 1 || acceptData.activity.join_show == 1) || acceptData.reside_show == 1 || acceptData.flow_show == 1)">
                    <ul>
                        <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.online_show == 1">
                        <strong class="count-font green">{{countObj.online || 0}}</strong>
                        <span>实时在线人数</span>
                        </li>
                        <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.join_show == 1">
                        <strong class="count-font orange">{{countObj.join || 0}}</strong>
                        <span>累计参与人数</span>
                        </li>
                        <li v-if="acceptData.reside_show == 1">
                        <strong class="count-font yellow">{{countObj.flow}}</strong>
                        <span>驻留人数</span>
                        </li>
                        <li v-if="acceptData.flow_show == 1">
                        <strong class="count-font purple">{{countObj.pass}}</strong>
                        <span>客流人次</span>
                        </li>
                    </ul>
                </div>
                 <div class="activity-main" ref="main">
                    <div class="activity-content-left" v-if="mode == 'h'">
                        <div class="activity-module-qrcode" v-if="acceptData && acceptData.activity.qrcode_img" :style="{'background-image': 'url('+ acceptData.activity.qrcode_img + ')'}">
                            <div class="activity-module-qrcode-item">
                                <img :src="acceptData.activity.qrcode_path"/>
                            </div>
                        </div>
                        <div class="activity-module-move">
                            <img :src="require('@/assets/questions/q_hand_h.gif')"/>
                        </div>
                        <div class="activity-module-prize" v-if="acceptData" :style="{'background-image': 'url('+ acceptData.activity.prize_img + ')'}">
                            <div class="activity-module-prize-carousel">
                                <el-carousel indicator-position="none" height="210px" :interval="5000" @change="carouselChange" v-if="prizeList.length > 0">
                                    <el-carousel-item v-for="(item,index) in prizeList" :key="index">
                                        <img v-if="item.victory_img" class="activity-module-prize-carousel-img" :src="item.victory_img" />
                                        <img v-else class="activity-module-prize-carousel-img" src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b"/>
                                        <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                                    </el-carousel-item>
                                </el-carousel>                                
                            </div>
                            <div class="prize-num">
                                <span>剩余</span><br/>
                                <span class="count">{{prizeList.length ? prizeList[currentIndex].victory_unused_num : 0}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="activity-content-middle" :style="{backgroundImage: 'url(' + (mode == 'h' ? acceptData?.activity.bg_img : acceptData?.activity.bg_h_img) + ')'}">
                        <div class="activity_ques-wrap">
                            <div class="activity_ques" id="questions">
                                <div class="activity_ques-item" v-if="answerObj.title">
                                    <img class="activity_ques-img" v-if="answerObj.img" :src="answerObj.img"/>
                                    <p class="activity_ques-title" :style="!answerObj.img ? 'flex: auto;text-align: center' : ''">
                                        {{answerObj.title}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="activity-title" :style="{'background-image': 'url('+ acceptData.activity.title_img + ')'}">
                            <div class="activity-time-down">
                                <svg class="activity_time_ring" width="180" height="180">
                                    <circle class="activity_time_ringb" cx="90" cy="90" r="76"></circle>
                                    <circle class="activity_time_ringbg" cx="90" cy="90" r="76"></circle>
                                    <circle class="activity_time_ringitem" id="ring" ref="ring" cx="90" cy="90" r="76"></circle>
                                </svg>
                                <span class="activity-time-down-num"><strong ref="ringTxt" v-if="acceptData.screen_type==0">0</strong></span>
                            </div>
                            <div class="activity-status">{{statusText[status]}}</div>
                        </div> 
                        <div class="activity-content">
                            <div class="activity-content-main">
                                <div class="activity-answer">
                                    <div class="activity-user-list">
                                        <ul id="userA" ref="userA" v-if="acceptData.screen_type==0"></ul>
                                    </div>
                                    <div class="activity-answer-text" id="answerA" ref="answerA">
                                        <div class="activity-answer-text-inner"  v-if="answerObj.option_a">
                                            <span>A</span>
                                            {{answerObj.option_a}}
                                        </div>
                                    </div>
                                </div>
                                <div class="activity-answer">
                                    <div class="activity-user-list">
                                        <ul id="userB" ref="userB" v-if="acceptData.screen_type==0"></ul>
                                    </div>
                                    <div class="activity-answer-text" id="answerB" ref="answerB">
                                        <div class="activity-answer-text-inner"  v-if="answerObj.option_b">
                                            <span>B</span>
                                            {{answerObj.option_b}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>
                    <div class="activity-content-side" v-if="mode == 'h' && (acceptData.activity.left_banner_img || acceptData.activity.right_banner_img)">
                        <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                            <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img"/>
                        </div>
                        <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                            <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img"/>
                        </div>
                    </div>
                 </div>
                 <div class="activity-content-bottom" v-if="mode == 'v'">
                    <div class="activity-module-qrcode"  :style="{'background-image': 'url('+ acceptData.activity.qrcode_img + ')'}">
                        <div class="activity-module-qrcode-item">
                            <img :src="acceptData.activity.qrcode_path"/>
                        </div>
                    </div>
                    <div class="activity-module-move">
                        <img :src="require('@/assets/questions/q_hand_v.gif')"/>
                    </div>
                    <div class="activity-module-prize" v-if="acceptData" :style="{'background-image': 'url('+ acceptData.activity.prize_img  + ')'}">
                        <div class="activity-module-prize-carousel">
                            <el-carousel indicator-position="none" height="210px" :interval="5000" @change="carouselChange" v-if="prizeList.length > 0">
                                <el-carousel-item v-for="(item,index) in prizeList" :key="index">
                                    <img v-if="item.victory_img" class="activity-module-prize-carousel-img" :src="item.victory_img" />
                                    <img v-else class="activity-module-prize-carousel-img" src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b"/>
                                    <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="prize-num">
                            <span>剩余</span><br/>
                            <span class="count">{{prizeList.length ? prizeList[currentIndex].victory_unused_num : 0}}</span>
                        </div>
                    </div>
                    
                 </div>
                 <div class="activity-adv" v-if="mode == 'v' && (acceptData.activity.left_banner_img || acceptData.activity.right_banner_img)">
                    <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                        <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img"/>
                    </div>
                    <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                        <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img"/>
                    </div>
                 </div>


                 <div class="mask_yindao heng"  ref="mask_yindao" :style="{ 'opacity': 0 }" v-if="acceptData.screen_type==0 && mode == 'h'">
                        <div :class="['pop-' + 'show']">
                            <div class="mask_yindao_wrap">
                                <div class="mask_yindao_content">
                                    <img class="sao" src="../../../assets/card/sao.png"
                                        />
                                    <div class="sao_wrap"
                                        :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'sao_wrap_img' : 'sao_wrap_img'">
                                        <img class="code" :src="acceptData.activity.qrcode_path" />
                                    </div>
                                    <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                                </div>
                                <div class="layerLst_wrap"
                                   >
                                    <div v-for="(item, index) in prizeCountListT" :key="index">
                                        <div class="victory_bg">
                                            <img class="victory_img" :src="item.victory_img" />
                                            <div class="victory_name">
                                                <p class="victory_text">{{ item.victory_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="mask_yindao ver"  v-if="mode == 'v' && this.prizeCountListT.length == 5 &&acceptData.screen_type==0" ref="mask_yindao"
                    :style="{ 'opacity': 0 }">
                    <div class="mask_yindao_wrap mask_yindao_wrap05">
                        <div class="mask_yindao_content">
                            <img class="sao" src="../../../assets/card/sao.png" />
                            <div class="sao_wrap">
                                <img class="code" :src="acceptData.activity.qrcode_path" />
                            </div>
                            <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                        </div>
                        <div class="layerLst_wrap layerLst_wrap05">
                            <div v-for="(item, index) in prizeCountListT" :key="index">
                                <div class="victory_bg">
                                    <img class="victory_img" :src="item.victory_img" />
                                    <div class="victory_name">
                                        <p class="victory_text">{{ item.victory_name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length == 4 &&acceptData.screen_type==0" ref="mask_yindao"
                    :style="{ 'opacity': 0 }">
                    <div class="mask_yindao_wrap mask_yindao_wrap04">
                        <div class="mask_yindao_content">
                            <img class="sao" src="../../../assets/card/sao.png" />
                            <div class="sao_wrap">
                                <img class="code" :src="acceptData.activity.qrcode_path" />
                            </div>
                            <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                        </div>
                        <div class="layerLst_wrap">
                            <div v-for="(item, index) in prizeCountListT" :key="index">
                                <div class="victory_bg">
                                    <img class="victory_img" :src="item.victory_img" />
                                    <div class="victory_name">
                                        <p class="victory_text">{{ item.victory_name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length < 4 &&acceptData.screen_type==0" ref="mask_yindao"
                    :style="{ 'opacity': 0 }">
                    <div class="mask_yindao_wrap mask_yindao_wrap03">
                        <div class="mask_yindao_content">
                            <img class="sao" src="../../../assets/card/sao.png" />
                            <div class="sao_wrap">
                                <img class="code" :src="acceptData.activity.qrcode_path" />
                            </div>
                            <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                        </div>
                        <div class="layerLst_wrap">
                            <div v-for="(item, index) in prizeCountListT" :key="index">
                                <div class="victory_bg">
                                    <img class="victory_img" :src="item.victory_img" />
                                    <div class="victory_name">
                                        <p class="victory_text">{{ item.victory_name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </block>
    </div>
</template>

<script>
import { TweenMax, Power0 } from 'gsap'
import {
    getOverView,
    getAnswerOne,
    getUserList,
    getPrizeList,
    getPrizeNumListCard,
} from '@/api/littleGameOne.js'
let pageCurrent = "index";
let selectedActivity = "";
export default {
  name: "questions",
  data() {
    return {
        isscaleSuo: true,
        prizeCountListT:[],
      mode: 'h',
      fullscreenLoading: false,
      state: 'going',
      countObj: {
        online: 0,
        join: 0,
        flow: 0,
        pass: 0
      },
      status: 0,
      statusText: ['等待玩家进入...', '等待下一道题', '正在答题'],
      prizeList: [],
      userList: [],
      oldUserList: [],
      userTimer: null,
      surplus: 0,
      diffArr: [], //存放改答案用户
      addArr: [], //存放新增加的用户
      lineObj: {'A' : 0, 'B': 0}, // 存放两个答案的当前行数
      randArr: {'A': [], 'B': []}, //存放一行随机数位置
      emptyPos: {'A': {0: [], 1: [], 2: [], 3: [], 4: []}, 'B': {0: [], 1: [], 2: [], 3: [], 4: []}},
      tempData: {'A': {}, 'B': {}},
      tempFlag: {'A': false, 'B': false},
      moreObj: {'A': [], 'B': []},
      moreCount: {'A' : 0, 'B': 0},
      acceptData: {
        current_select: {},
        activity: {}
      },
      coll_id: "",
      addInfo: {},
      count: 0,
      uniq: "",
      answerObj: {},
      changeFlag: 0,
      stopFlag: false,
      currentIndex: 0,
      scaleFen: 1,
      mleft: 0,
      mRight: 0,
      screen_part_type: 2,
      userLeft: 0,
      screenRadios: 1,
      mt:0,
    }
  },
  mounted() {  
    this.screen_type = localStorage.getItem('screen_type')
            let w = 0
            let h = 0
            if (this.screen_type == 9) {
                w = 1920
                h = 560
            } else if (this.screen_type == 8) {
                w = 1920
                h = 360
            } else if (this.screen_type == 7) {
                w = 1920
                h = 640
            } else if (this.screen_type == 6) {
                w = 1920
                h = 1080
            } else if (this.screen_type == 5) {
                w = 1600
                h = 1200
            } else if (this.screen_type == 4) {
                w = 1400
                h = 1400
            } else if (this.screen_type == 3) {
                w = 1000
                h = 1500
            } else if (this.screen_type == 2) {
                w = 1000
                h = 2000
            } else if (this.screen_type == 1) {//自定义
                w = localStorage.getItem('screen_width')
                h = localStorage.getItem('screen_length')
            } else if (this.screen_type == 0) {//默认自适应
                w = 1920
                h = 1080
                this.screen_part_type = 0
               
            }

            //横屏
            if (16 / 9 < w / h) {
                this.fenshu = 'h'
                this.wrapHeight = '100vh'
                this.wrapWidth = 16 / 9 * h + 'px'
                if (this.screen_type == 0) {//默认自适应
                    this.screenRadio = 1
                    this.mleft = 0
                } else {//3分|| 2分屏
                    this.screenRadio = 16 / 9 / (w / h)
                    this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
                }

            } else {
                //竖屏
                this.fenshu = 'v'
                this.wrapWidth = '100%'
                this.screenRadio = 1
                this.screenRadios = 9 / 16 * (w / h)
                console.log(this.screenRadios)
            }

 
    let that = this
        setInterval(() => {
            that.open();
            setTimeout(() => {
                that.close()
            }, 5000)
        }, 10000)
        
    this.coll_id = this.$route.params.id;
    this.fullscreenLoading = true;
    this.getWindowHide();
  },
  methods: {
    open() {
            TweenMax.to(this.$refs.mask_yindao, 1, {
                opacity: 1,
            });
        },
        close() {
            TweenMax.to(this.$refs.mask_yindao, 1, {
                opacity: 0,
            });
        },
    getParentData(data){
        this.uniq = data.activity.uniq;
        if (data.activity.left_banner_img != this.acceptData.activity.left_banner_img && data.activity.right_banner_img != this.acceptData.activity.right_banner_img) {
            this.resizeSetting(!!data.activity.left_banner_img);
        }
        this.acceptData = {...data}; 
        

        this.addInfo = data.activity.add_info
        console.log(data.activity.add_info)
        if (pageCurrent != data.current_select.current || selectedActivity != data.current_select.selectValue) {           
            if (this.countRefresh == 1) {
                window.location.reload();    
                this.changeFlag = new Date().getTime();   
                this.count = 0;
            }    
            this.countRefresh++    
        }
        pageCurrent = data.current_select.current;
        this.getActivityState(); 
    },
    getActivityState() {
        //表示活动未开始
        if (this.acceptData.activity) {
            //显示活动
            const current = new Date().getTime();
            if (current < this.acceptData.activity.begin_time * 1000) {
                this.state = 'unstart';
                this.changeFlag = new Date().getTime();
                this.count = 0;
                this.hideQuesPanel();
                this.hideAnser('A');
                this.hideAnser('B');
                if(this.userTimer) {
                    clearInterval(this.userTimer);
                }
            } else if (current > this.acceptData.activity.end_time * 1000 || this.acceptData.activity?.is_termi == 2) {
                this.state = 'finish';
                this.changeFlag = new Date().getTime();
            } else {
                selectedActivity = this.acceptData.current_select.selectValue;
                this.uniq = this.acceptData.activity.uniq;
                this.state = 'going';
                this.isscaleSuo = this.acceptData.activity.resolution
                this.screen_part_type = this.acceptData.screen_part_type
                console.log(this.screen_part_type)
            let w = 0
            let h = 0
            if (this.acceptData.screen_type == 9) {
                w = 1920
                h = 560
            } else if (this.acceptData.screen_type == 8) {
                w = 1920
                h = 360
            } else if (this.acceptData.screen_type == 7) {
                w = 1920
                h = 640
            } else if (this.acceptData.screen_type == 6) {
                w = 1920
                h = 1080
            } else if (this.acceptData.screen_type == 5) {
                w = 1600
                h = 1200
            } else if (this.acceptData.screen_type == 4) {
                w = 1400
                h = 1400
            } else if (this.acceptData.screen_type == 3) {
                w = 1000
                h = 1500
            } else if (this.acceptData.screen_type == 2) {
                w = 1000
                h = 2000
            } else if (this.acceptData.screen_type == 1) {//自定义
                w = this.acceptData.screen_width
                h = this.acceptData.screen_length
            } else if (this.acceptData.screen_type == 0) {//默认自适应
                w = 1920
                h = 1080
                this.screen_part_type = 0
               
            }

            //横屏
            if (16 / 9 < w / h) {
                this.fenshu = 'h'
                this.wrapHeight = '100vh'
                this.wrapWidth = 16 / 9 * h + 'px'
                if (this.acceptData.screen_type == 0) {//默认自适应
                    this.screenRadio = 1
                    this.mleft = 0
                } else {//3分|| 2分屏
                    this.screenRadio = 16 / 9 / (w / h)
                    this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
                }

            } else {
                this.fenshu = 'v'
                //竖屏
                this.wrapWidth = '100%'
                this.screenRadio = 1
                this.screenRadios = 9 / 16 * (w / h)
                if(this.$refs.activityWrap){
                    this.$refs.activityWrap.style.transform = "scaleY(" + this.screenRadios + ")";
                }
                
                if(this.acceptData.screen_part_type==3){//2分屏幕
                    const _h = document.body.clientHeight;
                    this.mt = (_h - (_h*this.screenRadios))/this.screenRadios +'px'
                }else if(this.acceptData.screen_part_type==2){//3分屏幕
                    if(this.$refs.activityWrap){
                    }
                    
                }
            }

                this.changeFlag = 0;
                if (this.count == 0) {   
                    this.cleanUser();
                    this.gameInit();
                    this.resizeSetting();    
                    window.addEventListener("resize", () => {
                        this.resizeSetting();
                    });               
                }           
                this.getPrizeList();       
                this.getPrizeListCountT();
                this.getOverViewNum(this.acceptData.activity.uniq);
                this.count++;
            }
        } else {
            //显示首页
            selectedActivity = this.acceptData.current_select.selectValue;
            this.uniq = this.acceptData.current_select.selectValue;
            this.state = 'going';
        }
        this.fullscreenLoading = false;

    },

    getPrizeListCountT() {
            getPrizeNumListCard({ colle_uniq: this.coll_id, uniq: this.uniq, limit: 5 }).then((res) => {
                for (let i in res.data) {
                    if (res.data[i].victory_level == 1) {
                        res.data[i].victory_level_name = '一等奖'
                    }
                    if (res.data[i].victory_level == 2) {
                        res.data[i].victory_level_name = '二等奖'
                    }
                    if (res.data[i].victory_level == 3) {
                        res.data[i].victory_level_name = '三等奖'
                    }
                    if (res.data[i].victory_level == 4) {
                        res.data[i].victory_level_name = '四等奖'
                    }
                    if (res.data[i].victory_level == 5) {
                        res.data[i].victory_level_name = '五等奖'
                    }
                    if (res.data[i].victory_level == 6) {
                        res.data[i].victory_level_name = '六等奖'
                    }
                    if (res.data[i].victory_level == 7) {
                        res.data[i].victory_level_name = '七等奖'
                    }
                    if (res.data[i].victory_level == 8) {
                        res.data[i].victory_level_name = '八等奖'
                    }
                    if (res.data[i].victory_level == 9) {
                        res.data[i].victory_level_name = '九等奖'
                    }
                    if (res.data[i].victory_level == 10) {
                        res.data[i].victory_level_name = '十等奖'
                    }

                }
                this.prizeCountListT = res.data
                console.log(this.prizeCountListT)


            })
        },

    getOverViewNum(uniq) {
       getOverView({
        uniq: uniq,
        colle_uniq: this.coll_id,
      }).then((res) => {
        if (res.status == 200) {
          this.countObj = res.data;
        }
        
      })
    },
    getOneAnswer() {
        return getAnswerOne({
            uniq: this.uniq,
            colle_uniq: this.coll_id,
        }).then((res) => {
            if (res.status == 200) {
                this.answerObj = res.data;
                this.showQuesPanel();
            } else {
                TweenMax.delayedCall(.5, () => {
                    this.getOneAnswer(); // 非200 继续请求接口
                })
            }
        })
    },
    gameInit(){
        const start_time = this.acceptData.activity.begin_time;
        const current_time = parseInt(new Date().getTime() / 1000);
        let time = 0;
        if (current_time - start_time >= 0 && current_time - start_time <= this.addInfo.start_wait_time) {
            time = this.addInfo.start_wait_time - (current_time - start_time)
        }
        this.status = 0;        
        this.setTimeCountDown(time, '#FF823C');        
    },
    gameStart() {
        this.status = 2;
        this.getOneAnswer() //获取题目
        this.showAnswer();
        this.getUser();
        this.stopFlag = false; 
        TweenMax.delayedCall(1, () => {
            this.setTimeCountDown(this.addInfo.answer_time, "#4E90FE");
        });
    },
    gameWait() {
        this.status = 1;        
        TweenMax.delayedCall(1, () => {
            this.setTimeCountDown(this.addInfo.get_answer_time, "#FF823C");
        });    
        if(this.userTimer) {
            clearInterval(this.userTimer);
        }        
        this.stopFlag = true;    
        this.$refs['answer' + this.answerObj.answer].classList.add('true'); 
        this.hideAnser(this.answerObj.answer == 'A' ? "B" : "A"); 
        TweenMax.delayedCall(this.addInfo.get_answer_time, () => {
            this.hideQuesPanel();
            this.hideAnser(this.answerObj.answer); 
        }); 
    },
    setTimeCountDown(time, color) {
        const _this = this;
        _this.$refs.ring.style.stroke = color;
        TweenMax.fromTo('#ring', time, {
            strokeDashoffset: 0
        }, {
            strokeDashoffset: 480,
            ease: Power0.easeIn,
            onUpdate: function() {
                if (_this.$refs.ringTxt){
                    _this.$refs.ringTxt.innerText = time - parseInt(this.time());
                }    
            },
            onComplete: () => {
                if (this.changeFlag == 0) {
                    this.status == 2 ? this.gameWait() : this.gameStart()
                }                
            }
        })
    },
    showQuesPanel() {
        TweenMax.to("#questions", 1, {
            top: 0,
        })
    },
    hideQuesPanel() {
        TweenMax.to("#questions", 1, {
            top: -250,
        })
    },
    showAnswer() {
        TweenMax.fromTo('.activity-answer-text', 1, {
            bottom: -200,
            opacity: 0,
        },{
            bottom: 0,
            opacity: 1,
        })
    },
    hideAnser(key) {
        this.$refs['answer' + key].classList.add('gas');
        TweenMax.delayedCall(.28, () => {
            this.$refs['answer' + key].classList.remove('gas');
            TweenMax.to('[data-group="' + key + '"]', .15, {
                opacity: 0,
                onComplete: () => {
                    if (key == this.answerObj.answer) {
                        this.cleanUser();
                    }
                }
            })
            TweenMax.to('#answer' + key, 1, {
                bottom: 1500,
                onComplete: () => {
                    this.$refs['answer' + key].classList.remove('true');
                }
            })
        });
        
    },
    getUser() {
        TweenMax.to(['#userA', '#userB'], .2, {
            opacity: 1,
        });

        this.userTimer = setInterval(() => {
            this.getUserData();
        }, 1000);        
    },

    getUserData() {
        getUserList({
            uniq: this.uniq,
            colle_uniq: this.coll_id,
            tid: this.answerObj.id
        }).then((res) => {
            if (res.status == 200) {                    
                const groupA = res.data.a_list;
                const groupB = res.data.b_list;
                this.formatterData(groupA, groupB);
                this.getUserDiff();
            }
        })
    },

    formatterData(list1, list2) {
        const data1 = list1.map((item) => {
            return {
                id: item.uid,
                nickname: item.nickname,
                image: item.avatar,
                selected: "A"
            }
        });
        const data2 = list2.map((item) => {
            return {
                id: item.uid,
                nickname: item.nickname,
                image: item.avatar,
                selected: "B"
            }
        });
        this.userList = [...data1, ...data2];
        console.log(this.userList)
    },
    getPrizeList() {
      getPrizeList({colle_uniq: this.coll_id, uniq: this.uniq}).then((res) => {
        if (res.status == 200) {
            this.prizeList = [...res.data];          
        }
      });
    },
    cleanUser() {
        this.userList = [];
        this.initData('A');
        this.initData('B');
        if(this.userTimer) {
            clearInterval(this.userTimer);
        }
    },
    initData(type) {
        this.$refs['user' + type].innerHTML = "";
        this.randArr[type] = [];
        this.lineObj[type] = 0;
        this.tempData[type] = {};
        this.tempFlag[type] = false;
        this.emptyPos[type] = {0: [], 1: [], 2: [], 3: [], 4: []};
        this.moreObj[type] = [];
        this.moreCount[type] = 0;
    },
    getUserDiff() {
        this.addArr = [];
        const _diffArr = [];
        if (this.stopFlag) {
            return false;
        }
        for (const item of this.userList) {
            let flag = false;
            for(const it of this.oldUserList) {
                if (item.id == it.id) {
                    if (
                        item.selected != it.selected && //新用户与就用户选择不同
                        !_diffArr.map(i => i.id).includes(item.id) && 
                        (
                            !this.diffArr.map(i => i.id).includes(item.id) 
                            || (this.diffArr.map(i => i.id).includes(item.id) && this.diffArr.find(i => i.id == item.id).selected != item.selected)
                        )) {
                        _diffArr.push(item);
                        // this.setUserMoveAnimate(item);
                    }
                    flag = true;
                }
            } 
            if (!flag) {
                if(this.addArr.indexOf(item.id) === -1) {
                    this.addArr.push(item);
                } else {
                    this.addArr[this.addArr.indexOf(item.id)] = item;
                }            
            }
        }

        this.oldUserList = JSON.parse(JSON.stringify(this.userList));
        console.log(111)
        console.log( this.oldUserList)
        this.setGroupUser('A');
        this.setGroupUser('B');
        if (_diffArr.length > 0) {   
            this.setUserMoveAnimate(_diffArr);  
        }         
        this.diffArr = JSON.parse(JSON.stringify(_diffArr));        
    },
    setGroupUser(type) {
        const group = this.addArr.filter(item => item.selected == type);
        for (let key = 0; key < group.length; key++) {         
            this.refreshPosition(type);
            this.setUserAddAnimate(group[key], this.randArr[type][0], (this.lineObj[type]));     
            this.reducePosition(type);
        }  
       
    },
    //用户移动
    setUserMoveAnimate(data) {
        for (let item of data) {    
            const curObj = document.getElementById(`user-item-${item.id}`);
            if (!curObj)  {
                return false;
            }
            const _beforeLine = curObj.getAttribute("data-line");
            const _beforeNo = curObj.getAttribute("data-no");
            const _beforeGroup = curObj.getAttribute("data-group");
            if (!this.emptyPos[_beforeGroup][_beforeLine]) {
                    this.emptyPos[_beforeGroup][_beforeLine] = [];
            }
            this.emptyPos[_beforeGroup][_beforeLine].push(_beforeNo);  
            this.refreshPosition(item.selected); 
            const parentGroup = curObj.parentNode.getAttribute("id");
            let groupSpace = 0;
            if (parentGroup) {
                parentGroup.indexOf(item.selected) > -1 ? groupSpace = 0 : groupSpace = (item.selected == 'A' ? -600 : 600)
            }            
            const bottom = this.lineObj[item.selected] * 89;
            const left = this.randArr[item.selected][0] * 68 + groupSpace;
            // console.log("移动过去的坐标", this.lineObj[item.selected], this.randArr[item.selected][0], item.selected, "高度", this.lineObj[item.selected] * 89, bottom)
  
            curObj.setAttribute("data-line", this.lineObj[item.selected]);
            curObj.setAttribute("data-no", this.randArr[item.selected][0]);
            curObj.setAttribute("data-group", item.selected);                    
            if (this.lineObj[item.selected] == 4 && this.randArr[item.selected][0] == 0 && this.moreCount[item.selected] > 0) {
                TweenMax.delayedCall(.2,() => {
                    this.getMorePosition(item.selected);
                })
                
            } else {
                curObj.classList.remove("user-more");
            }  
            this.reducePosition(item.selected); 
            TweenMax.to(curObj, .3, {
                left: left,
                bottom: bottom,
                opacity: 1,
                onComplete: () => {
                    const curBottom = curObj.getAttribute("style");
                    TweenMax.delayedCall(.3, () => {
                        const curBottom1 = curObj.getAttribute("style");
                        if (curBottom !== curBottom1) {
                            TweenMax.to(curObj, .1, {
                                bottom: bottom
                            })
                        }
                    })
                }
            });             
        }        
    },

    reducePosition(group) {
        if (!this.tempFlag[group]) {
            this.randArr[group].splice(0, 1);
            if (this.randArr[group].length == 0) {
                if (this.lineObj[group] < 4) {
                    this.lineObj[group]++;
                    this.getRandPosition(group);
                } else {
                    // 满员往左上角一直填充
                    this.lineObj[group] = 4;
                    this.randArr[group] = [0];
                } 
            }
        } else {
            this.emptyPos[group][this.lineObj[group]].splice(0, 1);
        }    
    },
    getMorePosition(group) {        
        const userA = document.getElementById("userA").childNodes;
        const userB = document.getElementById("userB").childNodes;
        let userChild = [];
        if (group == 'A') {
            userChild = [...userA, ...userB]
        } else {
            userChild = [...userB,...userA]
        }
        let count = 0, count1 = 0;
        for (let i = 0; i < userChild.length; i++) {
            if (userChild[i].getAttribute("data-group") == group) {
                count1++;
            }
        }
        console.log(count1, group);
        for (let i = 0; i < userChild.length; i++) {
            const curObj = userChild[i];
            const _line = curObj.getAttribute("data-line");
            const _num = curObj.getAttribute("data-no");
            const _group = curObj.getAttribute("data-group");
            const parentGroup = curObj.parentNode.getAttribute("id");            
            this.moreCount[group] = 0;
            if (_group == group) {
                let groupSpace = 0;
                if (parentGroup) {
                    parentGroup.indexOf(group) > -1 ? groupSpace = 0 : groupSpace = (group == 'A' ? -600 : 600)
                }  
                console.log(_line, _num, count1, ((_line == 4 && _num == 0) || (_line == 0 && _num == 7)) && count <= count1 - 40);
                if (((_line == 4 && _num == 0) || (_line == 0 && _num == 7)) && count <= count1 - 40 ) {                
                    curObj.classList.add("user-more");
                    count++;
                    TweenMax.to(curObj, .5, {
                        bottom: 20,
                        left: 7 * 68 + groupSpace,
                        onComplete: () => {
                            this.moreObj[group].unshift(curObj);
                            this.moreCount[group]++;                        
                            this.setMoreAnimate(group);                     
                        }
                    });
                } else {
                    // this.moreObj[group].filter(item => item.id !== _id);
                    userChild[i].classList.remove("user-more")
                }
            }
            
        }       
    },
    setMoreAnimate(group) {
         if (this.moreObj[group][0]) {
            TweenMax.to(this.moreObj[group][0], 1, {
                bottom: 20,
                opacity: 1
            });   
         }
        if (this.moreObj[group][1]) {
            TweenMax.to(this.moreObj[group][1], 1, {
                bottom: 10,
                opacity: .6
            });  
        }
        if (this.moreObj[group][2]) {
            TweenMax.to(this.moreObj[group][2], 1, {
                bottom: 0,
                opacity: .3
            });
        }
        if (this.moreObj[group].length > 3) {
            TweenMax.to(this.moreObj[group].splice(3), 1, {
                bottom: -10,
                opacity: 0
            });
        }
    },
    getRandPosition(type) {
        this.randArr[type] = this.randArr[type].length > 0 ? this.randArr[type] : this.getUniqueRand(0, 7, 8);   
    },
    refreshPosition(group) {
        //控制位置更新
        //优先看是否 emptyPos有空位否，有空位用emptyPos
        let flag = false;
        for (let i in this.emptyPos[group]) {
            const itemArr = Object.values(this.emptyPos[group][i]);   
            if (itemArr.length > 0) {
                if (!this.tempFlag[group]) {
                    this.tempData[group] = {
                        line: this.lineObj[group],
                        no: this.randArr[group]
                    }
                }                
                this.randArr[group] = itemArr;
                this.lineObj[group] = i;
                flag = true;
                this.tempFlag[group] = true;
                return false
            }
        }
        if (!flag) {
            if (this.tempFlag[group]) {
                if (this.tempData[group].no &&  Object.values(this.tempData[group].no).length > 0) {
                    this.randArr[group] = Object.values(this.tempData[group].no);
                    this.lineObj[group] = this.tempData[group].line;
                }
            } else {
                this.getRandPosition(group);
            }
            this.tempFlag[group] = false;           
        } 
    },
    //用户进入
    setUserAddAnimate(item, num, line) {
        const time = this.getRandNum(0, 500);
        setTimeout(() => {
            const _li = document.createElement("li");
            _li.className = "activity-answer-user";
            _li.setAttribute('id', `user-item-${item.id}`);
            _li.setAttribute('data-line', line);
            _li.setAttribute('data-no', num);
            _li.setAttribute('data-group', item.selected);
            _li.innerHTML = `<img class="activity-answer-user-head" src="${item.image}"/>
                <p class="activity-answer-user-nickname">${item.nickname}</p>`;
            _li.style.left = num * 68 + 'px';
           
            this.$refs['user' + item.selected].appendChild(_li);
            TweenMax.fromTo(`#user-item-${item.id}`, 1.2, {
                opacity: 1,
                bottom: this.$refs.main.clientHeight
            },{
                opacity: 1,
                bottom: line * 89
            });
            this.getMorePosition(item.selected)
        }, time);
    },
    getRandNum(minNum, maxNum, decimalNum) {
      let max = 0, min = 0;
      minNum <= maxNum ? (min = minNum, max = maxNum) : (min = maxNum, max = minNum);
      switch (arguments.length) {
          case 1:
              return Math.floor(Math.random() * (max + 1));
          case 2:
              return Math.floor(Math.random() * (max - min + 1) + min);
          case 3:
              return (Math.random() * (max - min) + min).toFixed(decimalNum);
          default:
              return Math.random();
      }
    },
    getUniqueRand(min, max, num) {
      const randArr = [];
      for (let i = 0; i < num; i++) {
        const rand = this.getRandNum(min, max);        
        if (randArr.indexOf(rand) == -1) {
          randArr.push(rand);
        } else {
          i--;
        }
      }
      return randArr
    },
    carouselChange(index) {
        this.currentIndex = index;
    },
    getWindowHide() {
        let start = 0, end = 0, time;
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState == 'hidden') {
                start = new Date().getTime();
            } else if (document.visibilityState == 'visible') {
                end = new Date().getTime();
                time = (end - start) / 1000;
                this.getActivityState();
                console.log('visibilitychange', time, this.state );
                
            }
            
        })
    },
    resizeSetting(hasImg) {
        const _w = document.body.clientWidth;
        const _h = document.body.clientHeight;     
        this.mode = _w >= _h ? "h" : "v";
        console.log(this.mode)
        let auto_w = 0, auto_h = 0;
        const _hasAdv = typeof hasImg == 'boolean' ? hasImg : this.acceptData.activity.left_banner_img || this.acceptData.activity.right_banner_img
        if (this.mode == 'h') {
            if(_hasAdv){
                auto_w = 1786;
            } else {
                auto_w = 1536;
            }
            auto_h = 1080
            const rate = auto_w / auto_h;
            this.isOverTop = rate < _w/_h;
            console.log( this.isOverTop)
            if (this.$refs.container) {
                 if (this.isOverTop) {
                    this.$refs.container.style.transform = "scale("+ _h / auto_h +")";
                    this.$refs.container.style.marginTop = -((auto_h - _h) / 2 - 1) + "px";
                    if(this.acceptData.screen_type!=0){
                        this.$refs.container.style.marginLeft = -(auto_w - _w) / 2 + "px";  
                    }
                } else {
                    this.$refs.container.style.transform = "scale("+ _w / auto_w +")";
                    this.$refs.container.style.marginTop = -((auto_h - _h) / 2 - 1) + "px";   
                    if(this.acceptData.screen_type!=0){
                        this.$refs.container.style.marginLeft = -(auto_w - _w) / 2 + "px";  
                    }         
                }
            }
           
        } else {
            if(_hasAdv){
                auto_h = 1808;
            } else {
                auto_h = 1648;
            }
            auto_w = 1200;
            const rate = auto_w / auto_h;
            this.isOverTop = rate < _w/_h;
            if (this.$refs.container) {
                if (this.isOverTop) {
                    this.$refs.container.style.transform = "scale("+ _h / auto_h +")";
                    this.$refs.container.style.marginTop = -((auto_h - _h) / 2 - 1) + "px";   
                    this.$refs.container.style.marginLeft = -(auto_w - _w) / 2 + "px"; 
                } else {
                    this.$refs.container.style.transform = "scale("+ _w / auto_w +")";
                    this.$refs.container.style.marginTop = -((auto_h - _h) / 2 - 1) + "px";   
                    this.$refs.container.style.marginLeft = -(auto_w - _w) / 2 + "px";   
                }
            }
        }
        console.log( _h / auto_h)
        console.log( _w / auto_w)
        console.log(_w / auto_w)
    }
  },
};
</script>

<style scoped lang="less">
@import url('./style.less');
</style>
<style lang="less">
.activity-user-list{
    height: calc(100% - 140px);
    box-sizing: border-box;
    position: relative;
    padding: 0 30px;
    display: flex;
    flex-direction: column-reverse;
    ul{
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        position: relative;
        margin-bottom: 10px;
        overflow: hidden;
    }
    .activity-answer-user{
        list-style-type: none;
        text-align: center;
        flex: none;
        width: 12.5%;
        padding: 0 0 10px 0;
        box-sizing: border-box;
        position: absolute;
        opacity: 0;
    }
    .activity-answer-user-head{
        width: 50px;
        height: 50px;
        border:2px solid #fff;
        border-radius: 30px;
        overflow: hidden;
    }
    .activity-answer-user-nickname{
        font-size: 12px;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;
        margin-bottom: 0;
    }
}
.user-more {
    .activity-answer-user-nickname{
        display: none;
    }
}
// .el-carousel__container{
//     height: 325px !important;
// }

.mCenter {
    margin: 0 auto;
}

.pd20 {
    padding: 0 40px;
}
.dingwei{
    position: absolute;
}
.resopnse{
    position: absolute;
    top: 0
}

.mt240{
    margin-top: -240px;
}
.weizhi{
    position: absolute;
}
.tebie{
     left: 50%!important;
 margin-left:-559px;
}
.liangfen{
    position: absolute;
    width: 100%;
}
.juzhong{
    position: absolute;
    bottom: 0;
}
</style>